import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        "chipDiv", "chipTemplate", "skillInput", "suggestDiv", "formSkillsInput",
        "chipDivInterviewScheduled", "chipTemplateInterviewScheduled", "skillInputInterviewScheduled", "suggestDivInterviewScheduled", "formInterviewScheduledInput", "notIncludedInterviewRequested",
        "chipDivPersonRegistered", "chipTemplatePersonRegistered", "skillInputPersonRegistered", "suggestDivPersonRegistered", "formPersonRegisteredInput", "notIncludedInterviewScheduled",
        "submitButton", "formDiv", "selectAllCheckbox", "selectAllCheckboxInterviewScheduled", "selectAllCheckboxPersonRegistered", "notIncludedPersonRegistered"
    ];

    suggestOptions = [];
    suggestOptionsInterviewScheduled = [];
    suggestOptionsPersonRegistered = [];

    connect() {
        if (this.hasChipDivTarget) {
            this.setInitialSkills(this.chipDivTarget, this.chipTemplateTarget, this.suggestDivTarget, this.formSkillsInputTarget);
            this.setOptions(this.suggestDivTarget, this.chipDivTarget, this.chipTemplateTarget, this.skillInputTarget, "requested");
        }
        
        if (this.hasChipDivInterviewScheduledTarget) {
            this.setInitialSkills(this.chipDivInterviewScheduledTarget, this.chipTemplateInterviewScheduledTarget, this.suggestDivInterviewScheduledTarget, this.formInterviewScheduledInputTarget);
            this.setOptions(this.suggestDivInterviewScheduledTarget, this.chipDivInterviewScheduledTarget, this.chipTemplateInterviewScheduledTarget, this.skillInputInterviewScheduledTarget, "interviewScheduled");
        }
        
        if (this.hasChipDivPersonRegisteredTarget) {
            this.setInitialSkills(this.chipDivPersonRegisteredTarget, this.chipTemplatePersonRegisteredTarget, this.suggestDivPersonRegisteredTarget, this.formPersonRegisteredInputTarget);
            this.setOptions(this.suggestDivPersonRegisteredTarget, this.chipDivPersonRegisteredTarget, this.chipTemplatePersonRegisteredTarget, this.skillInputPersonRegisteredTarget, "personRegistered");
        }

        this.checkSelectAllOnLoad();

        if (this.hasChipDivTarget) {
            this.initializeSkillSection(
            this.skillInputTarget,
            this.chipDivTarget,
            this.suggestDivTarget,
            this.suggestOptions,
            this.chipTemplateTarget,
            this.notIncludedInterviewRequestedTarget);
        }
        
        if (this.hasChipDivInterviewScheduledTarget) {
            this.initializeSkillSection(
            this.skillInputInterviewScheduledTarget,
            this.chipDivInterviewScheduledTarget,
            this.suggestDivInterviewScheduledTarget,
            this.suggestOptionsInterviewScheduled,
            this.chipTemplateInterviewScheduledTarget,
            this.notIncludedInterviewScheduledTarget);
        }
        
        if (this.hasChipDivPersonRegisteredTarget) {
            this.initializeSkillSection(
            this.skillInputPersonRegisteredTarget,
            this.chipDivPersonRegisteredTarget,
            this.suggestDivPersonRegisteredTarget,
            this.suggestOptionsPersonRegistered,
            this.chipTemplatePersonRegisteredTarget,
            this.notIncludedPersonRegisteredTarget);
        }
        if (this.hasSubmitButtonTarget) {
            this.submitButtonTarget.addEventListener("click", this.handleSubmit.bind(this));
        }
    }

    initializeSkillSection(skillInput, chipDiv, suggestDiv, suggestOptions, chipTemplate, notIncludedMessage) {
        if (skillInput) {
            skillInput.addEventListener("input", (event) =>
                this.handleSkillInput(event, chipDiv, suggestDiv, suggestOptions, chipTemplate, skillInput)
            );
            skillInput.addEventListener("keydown", (event) =>
                this.handleEnterPress(event, skillInput, chipDiv, suggestDiv, chipTemplate, suggestOptions, notIncludedMessage)
            );
        }
    }

    setInitialSkills(chipDiv, chipTemplate, suggestDiv, skillInput) {
        if (!chipDiv) return;
        const initSkills = chipDiv.textContent;
        chipDiv.innerHTML = "";
        initSkills.split(",").forEach(skill => {
            if (skill.trim() !== "") {
                this.addSkill(skill.trim().charAt(0) + skill.trim().slice(1), chipDiv, chipTemplate, suggestDiv, skillInput);
            }
        });
        this.setVisibility(chipDiv, true);
    }

    handleEnterPress(event, skillInput, chipDiv, suggestDiv, chipTemplate, suggestOptions, notIncludedMessage) {
        if (event.key === 'Enter') {
            event.preventDefault();
            skillInput.value = skillInput.value.trim() + ',';
            this.handleSkillInput(event, chipDiv, suggestDiv, suggestOptions, chipTemplate, skillInput, notIncludedMessage);
        }
    }

    handleSkillInput(event, chipDiv, suggestDiv, suggestOptions, chipTemplate, skillInput, notIncludedMessage) {
        if (!suggestOptions) return;

        const inputText = event.target.value.trim();
        const currentChoices = Array.from(chipDiv.querySelectorAll("p")).map(option => option.textContent);

        if (inputText.includes(",")) {
            const skills = inputText.split(',').map(skill => skill.trim());
            
            skills.forEach(skill => {
                const isSkillInOptions = Array.from(suggestOptions).some(option => 
                    option.textContent.toLowerCase() === skill.toLowerCase()
                );

                if (skill !== "" && !currentChoices.some(choice => choice.toLowerCase() === skill.toLowerCase())) {
                    if (isSkillInOptions) {
                        this.addSkill(skill, chipDiv, chipTemplate, suggestDiv, skillInput);
                    } else {
                        this.showNotIncludedMessage(notIncludedMessage);
                    }
                }
            });
            event.target.value = "";
            this.setVisibility(suggestDiv, false);
            return;
        }

        if (inputText.length > 0) {
            const relevantOptions = Array.from(suggestOptions).filter(option => option.textContent.toLowerCase().includes(inputText.toLowerCase()));

            if (relevantOptions.length > 0) {
                Array.from(suggestOptions).forEach(option => {
                    this.setVisibility(option, false);
                });
                relevantOptions.forEach(option => {
                    if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                        this.setVisibility(option, true);
                    }
                });
                this.setVisibility(suggestDiv, true);
            } else {
                this.setVisibility(suggestDiv, false);
            }
        } else {
            this.setVisibility(suggestDiv, false);
        }
    }

    setVisibility(element, isVisible) {
        if (element) { // Check if the element exists
            if (isVisible) {
                element.classList.remove("hidden");
            } else if (!isVisible && !element.classList.contains("hidden")) {
                element.classList.add("hidden");
            }
        }
    }

    setOptions(suggestDiv, chipDiv, chipTemplate, skillInput, context) {
        if (!suggestDiv) return;
        let suggestOptions = this.getSuggestOptions(context);
        suggestOptions.length = 0;
        suggestOptions.push(...Array.from(suggestDiv.querySelectorAll("p")));

        suggestOptions.forEach(option => {
            option.addEventListener("click", () => {
                this.addSkill(option.textContent, chipDiv, chipTemplate, suggestDiv, skillInput);
            });
        });
    }

    addSkill(chipText, chipDiv, chipTemplate, suggestDiv, skillInput) {
        if (!chipDiv || !chipTemplate) return;

        const currentSkills = Array.from(chipDiv.querySelectorAll("p")).map(skill => skill.textContent.toLowerCase());
        const lowerCaseChipText = chipText.toLowerCase();

        if (!currentSkills.includes(lowerCaseChipText)) {
            const chipTemplateContent = chipTemplate.content.cloneNode(true);
            const pTag = chipTemplateContent.querySelector("p");
            pTag.textContent = chipText;

            const chipElement = chipTemplateContent.querySelector("span");
            const deleteIcon = chipElement.querySelector(".delete-icon");
            deleteIcon.addEventListener("click", (e) => {
                e.stopPropagation();
                chipElement.remove();
            });

            chipDiv.appendChild(chipTemplateContent);

            skillInput.value = "";
            this.setVisibility(suggestDiv, false);
        }
    }

    handleUncommitted(skillInput, chipDiv, chipTemplate, suggestDiv, suggestOptions, formSkillsInput) {
        if (!suggestOptions) return; // Handle case where suggestOptions might be undefined or null

        const inputText = skillInput.value.trim() + ",";
        const currentChoices = Array.from(chipDiv.querySelectorAll("p")).map(option => option.textContent);

        if (inputText.endsWith(",")) {
            if (inputText.length > 1 && !currentChoices.some(choice => choice.toLowerCase() === inputText.slice(0, -1).trim().toLowerCase())) {
                this.addSkill(inputText.slice(0, -1).trim(), chipDiv, chipTemplate, suggestDiv, skillInput);
            }
            skillInput.value = "";
            this.setVisibility(suggestDiv, false);
            return;
        }

        if (inputText.length > 0) {
            const relevantOptions = Array.from(suggestOptions).filter(option => option.textContent.toLowerCase().includes(inputText.toLowerCase()));

            if (relevantOptions.length > 0) {
                Array.from(suggestOptions).forEach(option => {
                    this.setVisibility(option, false);
                });
                relevantOptions.forEach(option => {
                    if (!currentChoices.some(choice => choice.toLowerCase() === option.textContent.toLowerCase())) {
                        this.setVisibility(option, true);
                    }
                });
                this.setVisibility(suggestDiv, true);
            } else {
                this.setVisibility(suggestDiv, false);
            }
        } else {
            this.setVisibility(suggestDiv, false);
        }
    }

    isSkillInSuggestions(skill, suggestOptions) {
        return Array.from(suggestOptions).some(option => 
            option.textContent.toLowerCase() === skill.toLowerCase()
        );
    }

    showNotIncludedMessage(notIncludedMessage) {
        if (notIncludedMessage) {
            notIncludedMessage.classList.remove("hidden");
            setTimeout(() => {
                notIncludedMessage.classList.add("hidden");
            }, 3000);
        }
    }

    finalSubmit(formSkillsInput, chipDiv) {
        if (!formSkillsInput || !chipDiv) return;

        formSkillsInput.disabled = false;

        const currentChoices = Array.from(chipDiv.querySelectorAll("p")).map(option => option.textContent);
        const skills = currentChoices.join(",");

        formSkillsInput.value = skills;
    }

    clearSkills(event) {
        event.stopPropagation();
        if (this.chipDivTarget) {
            this.chipDivTarget.innerHTML = "";
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.hasChipDivTarget) {
            this.handleUncommitted(
                this.skillInputTarget,
                this.chipDivTarget,
                this.chipTemplateTarget,
                this.suggestDivTarget,
                this.suggestOptions,
                this.formSkillsInputTarget
            );

            this.finalSubmit(
                this.formSkillsInputTarget,
                this.chipDivTarget
            );
        }
        
        if (this.hasChipDivInterviewScheduledTarget) {
            this.handleUncommitted(
                this.skillInputInterviewScheduledTarget,
                this.chipDivInterviewScheduledTarget,
                this.chipTemplateInterviewScheduledTarget,
                this.suggestDivInterviewScheduledTarget,
                this.suggestOptionsInterviewScheduled,
                this.formInterviewScheduledInputTarget
            );
            this.finalSubmit(
                this.formInterviewScheduledInputTarget,
                this.chipDivInterviewScheduledTarget
            );
        }
        
        if (this.hasChipDivPersonRegisteredTarget) {
            this.handleUncommitted(
                this.skillInputPersonRegisteredTarget,
                this.chipDivPersonRegisteredTarget,
                this.chipTemplatePersonRegisteredTarget,
                this.suggestDivPersonRegisteredTarget,
                this.suggestOptionsPersonRegistered,
                this.formPersonRegisteredInputTarget
            );

            this.finalSubmit(
                this.formPersonRegisteredInputTarget,
                this.chipDivPersonRegisteredTarget
            );
        }

        if (this.formDivTarget && this.formDivTarget.checkValidity()) {
            this.formDivTarget.submit();
        } else if (this.formDivTarget) {
            this.formDivTarget.reportValidity();
            setTimeout(() => {
                if (this.hasFormSkillsInputTarget) this.formSkillsInputTarget.disabled = true;
                if (this.formInterviewScheduledInputTarget) this.formInterviewScheduledInputTarget.disabled = true;
                if (this.formPersonRegisteredInputTarget) this.formPersonRegisteredInputTarget.disabled = true;
            }, 5000);
        }
    }

    getSuggestOptions(context) {
        switch (context) {
            case 'requested':
                return this.suggestOptions;
            case 'interviewScheduled':
                return this.suggestOptionsInterviewScheduled;
            case 'personRegistered':
                return this.suggestOptionsPersonRegistered;
            default:
                return [];
        }
    }

    handleSelectAll(event) {
        const isChecked = event.target.checked;
        console.log("checked")

        let suggestOptions;
        let chipDiv;
        let chipTemplate;
        let skillInput;

        if (event.target === this.selectAllCheckboxTarget) {
            suggestOptions = this.suggestOptions;
            chipDiv = this.chipDivTarget;
            chipTemplate = this.chipTemplateTarget;
            skillInput = this.skillInputTarget;
        } else if (event.target === this.selectAllCheckboxInterviewScheduledTarget) {
            suggestOptions = this.suggestOptionsInterviewScheduled;
            chipDiv = this.chipDivInterviewScheduledTarget;
            chipTemplate = this.chipTemplateInterviewScheduledTarget;
            skillInput = this.skillInputInterviewScheduledTarget;
        } else if (event.target === this.selectAllCheckboxPersonRegisteredTarget) {
            suggestOptions = this.suggestOptionsPersonRegistered;
            chipDiv = this.chipDivPersonRegisteredTarget;
            chipTemplate = this.chipTemplatePersonRegisteredTarget;
            skillInput = this.skillInputPersonRegisteredTarget;
        }

        if (isChecked) {
            suggestOptions.forEach(option => {
                this.addSkill(option.textContent, chipDiv, chipTemplate, null, skillInput);
            });
        } else {
            chipDiv.innerHTML = "";
        }
    }

    checkSelectAllOnLoad() {
        console.log("CHECK SELECT");

        // Define all checkbox and option sets
        const checkboxSets = [
            {
                selectAllCheckbox: this.hasSelectAllCheckboxTarget ? this.selectAllCheckboxTarget : null,
                suggestOptions: this.suggestOptions || [],
                chipDiv: this.hasChipDivTarget ? this.chipDivTarget : null
            },
            {
                selectAllCheckbox: this.hasSelectAllCheckboxInterviewScheduledTarget ? this.selectAllCheckboxInterviewScheduledTarget : null,
                suggestOptions: this.suggestOptionsInterviewScheduled || [],
                chipDiv: this.hasChipDivInterviewScheduledTarget ? this.chipDivInterviewScheduledTarget : null
            },
            {
                selectAllCheckbox: this.hasSelectAllCheckboxPersonRegisteredTarget ? this.selectAllCheckboxPersonRegisteredTarget : null,
                suggestOptions: this.suggestOptionsPersonRegistered || [],
                chipDiv: this.hasChipDivPersonRegisteredTarget ? this.chipDivPersonRegisteredTarget : null
            }
        ];


        checkboxSets.forEach(({ selectAllCheckbox, suggestOptions, chipDiv }) => {
            if (!selectAllCheckbox) {
                console.warn("Aucune case à cocher pour", chipDiv ? "élément cible" : "set de cases à cocher");
            }

            if (chipDiv && suggestOptions && suggestOptions.length > 0) {
                const selectedSkills = Array.from(chipDiv.querySelectorAll("p")).map(skill => skill.textContent.toLowerCase());
                console.log("Selected skills length", selectedSkills.length);

                if (selectedSkills.length === suggestOptions.length) {
                    if (selectAllCheckbox) {
                        selectAllCheckbox.checked = true;
                    }
                } else {
                    if (selectAllCheckbox) {
                        selectAllCheckbox.checked = false;
                    }
                }
            } else {
                console.log("Suggest options are missing or empty for", chipDiv);
            }
        });
    }
}
