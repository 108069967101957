import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "wrapper", "button"];

  connect() {
    console.log("Connected to see more");
    this.collapsedHeight = 240;
    this.lineHeight = 24;
    this.expanded = false;

    this.viewFrom = this.element.dataset.context;

    requestAnimationFrame(() => this.checkHeight());

    window.addEventListener("resize", this.handleResize.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  handleResize() {
    this.checkHeight();
  }

  checkHeight() {
    if (!this.hasContentTarget || !this.hasWrapperTarget || !this.hasButtonTarget) {
      return;
    }

    const fullHeight = this.contentTarget.scrollHeight;
    if (fullHeight > this.collapsedHeight) {
      this.buttonTarget.parentElement.style.display = "flex";
      if (!this.expanded) {
        const adjustedHeight = this.getAdjustedHeight(this.collapsedHeight);
        this.wrapperTarget.style.maxHeight = `${adjustedHeight}px`;
        this.wrapperTarget.classList.add("collapsed");
      }
    } else {
      this.buttonTarget.parentElement.style.display = "none";
      this.wrapperTarget.classList.remove("collapsed");
      this.wrapperTarget.style.maxHeight = "none";
    }
  }

  getAdjustedHeight(height) {
    return Math.floor(height / this.lineHeight) * this.lineHeight;
  }

  toggle(event) {
    const button = event.currentTarget;

    if (!this.hasWrapperTarget || !this.hasButtonTarget) {
      return;
    }

    if (this.expanded) {
      const adjustedHeight = this.getAdjustedHeight(this.collapsedHeight);
      this.wrapperTarget.style.maxHeight = `${adjustedHeight}px`;
      button.textContent =
        this.viewFrom === "public-submission"
          ? "View full job description"
          : "See More";
      this.wrapperTarget.classList.add("collapsed");
    } else {
      this.wrapperTarget.style.maxHeight = "none";
      button.textContent =
        this.viewFrom === "public-submission"
          ? "View less job description"
          : "See Less";
      this.wrapperTarget.classList.remove("collapsed");
    }

    this.expanded = !this.expanded;
  }
}
