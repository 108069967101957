import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spinner"];

  connect() {
    window.addEventListener("focus", this.hideSpinner.bind(this));
  }

  disconnect() {
    window.removeEventListener("focus", this.hideSpinner.bind(this));
  }

  download(event) {
    event.preventDefault();
    this.spinnerTarget.classList.remove("hidden");
    const link = event.target;
    const url = link.getAttribute("href");

    const form = document.createElement("form");
    form.action = url;
    form.method = "GET";
    document.body.appendChild(form);
    form.submit();
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }
}
